<template>
  <el-dialog
    title="配置"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="reset"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleFormRef"
      label-width="100px"
    >
      <el-form-item label="系统编号：">
        <el-input v-model="ruleForm.id_device" disabled></el-input>
      </el-form-item>

      <el-form-item label="项目：" prop="id_project">
        <el-select
          v-model="ruleForm.id_project"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in projectList"
            :key="item.id"
            :label="item.name_project"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="系统名称：" prop="name">
        <el-input v-model.trim="ruleForm.name" maxlength="16"></el-input>
      </el-form-item>

      <el-form-item label="系统地址：" prop="addr">
        <el-input v-model.trim="ruleForm.addr" maxlength="24"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmConfig">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetProjectList, ConfigSystem2 } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 表单
      ruleForm: {
        id_device: '',
        name: '',
        addr: ''
      },
      // 规则
      rules: {
        id_project: [
          { required: true, message: '请选择项目', trigger: 'change' }
        ],
        name: [{ required: true, message: '请输入系统名称', trigger: 'blur' }],
        addr: [{ required: true, message: '请输入系统地址', trigger: 'blur' }]
      },
      // 设备列表
      projectList: []
    }
  },
  methods: {
    // 关闭时重置表单
    reset() {
      this.$refs.ruleFormRef.resetFields()
    },

    // 外部调用
    config(row) {
      this.getProjectList(row.id_operator)
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.id_device = row.id_device
      })
    },

    // 获取项目
    async getProjectList(id) {
      const { ret, data, msg } = await GetProjectList({
        id_operator: id
      })
      if (ret !== 0) {
        return this.$message.error(msg || '获取项目列表失败')
      }
      this.projectList = data.data
    },

    // 确定配置
    confirmConfig() {
      this.$refs.ruleFormRef.validate(async (_) => {
        if (!_) return
        const params = {
          id_project: this.ruleForm.id_project,
          name: this.ruleForm.name,
          addr: this.ruleForm.addr,
          device_array: [this.ruleForm.id_device]
        }
        const { ret, data, msg } = await ConfigSystem2(params)
        if (ret !== 0) {
          return this.$message.error(msg || '配置设备失败!')
        }
        this.$message.success('配置成功')
        this.dialogVisible = false
        this.$emit('refresh')
      })
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
