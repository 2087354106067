<template>
  <div class="main">
    <div class="not_config">
      <!-- 选择器 -->
      <div class="select_box">
        <!-- 运营商 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-supAdmin
          v-model="id_operator"
          placeholder="请选择运营商"
        >
          <el-option
            v-for="item in operatorList"
            :key="item.id_operator"
            :label="item.name_operator"
            :value="item.id_operator"
          ></el-option>
        </el-select>

        <!-- 系统编号 -->
        <el-input
          class="ipt_box mr12 mt12"
          v-model="id_device"
          placeholder="请输入系统编号"
        />

        <!-- 系统状态 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="state"
          placeholder="请选择系统状态"
        >
          <el-option
            label="在线"
            :value="1"
          ></el-option>
          <el-option
            label="离线"
            :value="4"
          ></el-option>
        </el-select>

        <!-- 系统类型 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="device_type"
          placeholder="请选择系统类型"
        >
          <el-option
            label="无线版"
            :value="1"
          ></el-option>
          <el-option
            label="普通版"
            :value="0"
          ></el-option>
        </el-select>

        <el-date-picker
          class="time_box mr12 mt12"
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <div class="mr12 mt12">
          <el-button
            type="primary"
            size="small"
            @click="search"
          >查询</el-button>
          <el-button
            size="small"
            type="primary"
            plain
            @click="reset"
          >重置</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="tabel_box">
        <div
          class="distrution_some"
          v-supAdmin
        >
          <i
            class="el-icon-connection"
            color="#2a47b0"
            @click="isDistrutionSome = true"
          ></i>
          <span @click="isDistrutionSome = true">批量分配</span>
        </div>

        <el-table
          :data="notConfigList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb',
            height: '40px'
          }"
          :stripe="true"
          size="small"
          ref="tableRef"
          @selection-change="selectCheckbox"
        >
          <el-table-column
            type="selection"
            width="40"
            v-if="isDistrutionSome"
          >
          </el-table-column>
          <el-table-column
            prop="id_device"
            label="系统编号"
          ></el-table-column>
          <el-table-column label="系统类型">
            <template v-slot="{ row }">
              <span>{{ row.heater_device_type ? '无线版' : '普通版' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="版本号">
            <template v-slot="{ row }">
              <span>{{ row.heater_device_ver || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="运营商">
            <template v-slot="scope">
              <span>
                {{ scope.row.name_operator || '-' }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="系统状态">
            <template v-slot="scope">
              <span>
                {{ scope.row.state | stateFmt }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="创建时间">
            <template v-slot="scope">
              <span>
                {{ scope.row.time_create || '-' }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template v-slot="scope">
              <div class="operate_container">
                <span
                  class="operate_box cursor"
                  @click="distrution(scope.row)"
                  v-supAdmin
                >
                  <img
                    src="../../../assets/fenpei_icon.png"
                    class="operate_img"
                    alt=""
                  />
                  <span class="edit_table">分配</span>
                </span>

                <span
                  class="operate_box cursor"
                  @click="configDevice(scope.row)"
                >
                  <img
                    src="../../../assets/peizhi_icon.png"
                    class="operate_img"
                    alt=""
                  />
                  <span class="edit_table">配置</span>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 批量分配 -->
        <div
          class="disctrution_box"
          v-if="isDistrutionSome"
        >
          <el-button
            type="info"
            @click="cancelDistrution"
          >取消</el-button>
          <el-button
            type="primary"
            style="margin-left: 36px"
            @click="confirmDistrutionSome"
          >确定</el-button>
        </div>

        <!--分页-->
        <div
          class="pagination_box"
          v-else
        >
          <el-pagination
            @size-change="sizeChange"
            @current-change="CurrentChange"
            :current-page="page_id + 1"
            :page-sizes="[20, 50, 100, 200]"
            :page-size="page_num"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 分配通用弹窗 -->
    <Distribution
      ref="distrutionRef"
      @refresh="getNotConfigList"
    />
    <!-- 配置通用弹窗 -->
    <ConfigDevice
      ref="configDeviceRef"
      @refresh="getNotConfigList"
    />
  </div>
</template>

<script>
import { GetOperatorData, GetNotConfigList } from '@/api'
import Distribution from './dialog/distribution.vue'
import ConfigDevice from './dialog/config_device.vue'

export default {
  name: 'not_config',
  components: { Distribution, ConfigDevice },
  data() {
    return {
      // 当前登录的角色类型
      role: '',
      page_id: 0,
      page_num: 20,
      total: 0,
      // 系统编号
      id_device: '',
      // 运营商
      id_operator: '',
      // 系统状态 0:在线 3:忙碌 4:故障
      state: '',
      // 设备类型
      device_type: '',
      // 添加日期
      date: ['', ''],
      // 运营商列表
      operatorList: [],
      // 未配置系统列表
      notConfigList: [],
      // 是否批量分配
      isDistrutionSome: false,
      // 所有的选中项
      selectedRows: []
    }
  },

  methods: {
    // 查询
    search() {
      this.page_id = 0
      this.getNotConfigList()
    },

    // 重置
    reset() {
      this.page_id = 0
      this.id_device = ''
      this.id_operator = ''
      this.state = ''
      this.device_type = ''
      this.date = null
      this.getNotConfigList()
    },

    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s
      this.page_id = 0
      this.getNotConfigList()
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p - 1
      this.getNotConfigList()
    },

    // 获取运营商
    async getOperator() {
      const { ret, data, msg } = await GetOperatorData()
      if (ret !== 0) {
        return this.$message.error(msg || '获取运营商列表失败!')
      }
      this.operatorList = data.data
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_device) {
        params.id_device = this.id_device
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.state !== '') {
        params.state = this.state
      }
      if (this.device_type !== '') {
        params.heater_device_type = this.device_type
      }
      if (this.date && this.date[0] && this.date[1]) {
        params.time_start = this.date[0] + ' 00:00:00'
        params.time_end = this.date[1] + ' 23:59:59'
      }
      return params
    },

    // 获取未配置的系统列表
    async getNotConfigList() {
      this.cancelDistrution()
      const params = this.getParams()
      const { ret, data, msg } = await GetNotConfigList(params)
      if (ret !== 0) {
        return this.$message.error(msg || '获取未配置系统失败!')
      }
      this.total = data.cnt_all
      this.notConfigList = data.data
    },

    // 分配
    distrution(row) {
      this.$refs.distrutionRef.open(row)
    },

    // 复选框发生变化
    selectCheckbox(e) {
      this.selectedRows = e
    },

    // 取消多选
    cancelDistrution() {
      this.$refs.tableRef && this.$refs.tableRef.clearSelection()
      this.isDistrutionSome = false
    },

    // 确定 批量分配
    confirmDistrutionSome() {
      if (!this.selectedRows.length) {
        this.$message.warning('暂无选中项')
        return
      }
      const ids = []
      this.selectedRows.map((j) => {
        ids.push(j.id_device)
      })
      this.$refs.distrutionRef.open(ids)
    },

    // 配置设备
    configDevice(row) {
      this.$refs.configDeviceRef.config(row)
    }
  },

  created() {
    this.role = this.$store.getters.role
    if (this.role === 100) {
      this.getOperator()
    }
    this.getNotConfigList()
  },

  filters: {
    stateFmt(v) {
      switch (v) {
        case 1:
          return '在线'
        default:
          return '离线'
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.not_config {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  border-radius: $radius;
  padding: 12px;
}

.tabel_box {
  padding: 0 12px;
  margin-top: 12px;

  .operate_container {
    display: flex;
    align-items: center;

    .operate_box {
      margin: 0 6px;
      display: flex;
      align-items: center;

      .operate_img {
        width: 22.8px;
        height: 22.8px;
      }
    }
  }
}

.distrution_some {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $theme;
  cursor: pointer;

  i {
    font-size: 14px;
  }

  span {
    margin-left: 4px;
    font-size: 14px;
  }
}

.disctrution_box {
  height: 60px;
  line-height: 60px;
  text-align: center;
}
</style>
