<template>
  <el-dialog
    title="分配"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="reset"
  >
    <div class="select_item">
      <span>运营商：</span>
      <el-select
        v-model="id_operator"
        placeholder="请选择运营商"
        style="width: 400px"
      >
        <el-option
          v-for="item in operatorList"
          :key="item.id_operator"
          :label="item.name_operator"
          :value="item.id_operator"
        ></el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmDistrution">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetOperatorData, ConfigSystem } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 运营商id
      id_operator: '',
      // 运营商列表
      operatorList: [],
      // 设备列表
      deviceList: []
    }
  },
  methods: {
    open(row) {
      if (Object.prototype.toString.call(row) === '[object Object]') {
        this.deviceList = [row.id_device]
      }
      if (Object.prototype.toString.call(row) === '[object Array]') {
        this.deviceList = row
      }
      this.dialogVisible = true
      this.getOperator()
    },

    reset() {
      this.id_operator = ''
      this.operatorList = []
    },

    async getOperator() {
      const { ret, data, msg } = await GetOperatorData()
      if (ret !== 0) {
        return this.$message.error(msg || '获取运营商列表失败!')
      }
      this.operatorList = data.data
    },

    // 确定分配
    async confirmDistrution() {
      if (!this.id_operator) {
        this.$message.warning('请选择运营商!')
        return
      }
      const params = {
        id_operator: this.id_operator,
        device_array: this.deviceList
      }
      const { ret, data, msg } = await ConfigSystem(params)
      if (ret !== 0) {
        return this.$message.error(msg || '分配失败')
      }
      this.$message.success('分配成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang='scss' scoped>
.select_item {
  text-align: center;
}
</style>
